<template>
  <div id="Footer">
    <div class="contact-cont" id="contactanos"
    :style="{'background-image':'url('+isWebp(require('../../assets/campo1.webp'), require('../../assets/campo1.png'))+')'}">
      <div class="contact-sec">
        <p
          class="titulo"
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-duration="700"
          data-aos-delay="40"
        >
          CONTACTANOS<br />
          PARA RESOLVER TUS DUDAS
        </p>
        <form @submit.prevent="enviarMail(name, phone, email, mensaje)">
          <div class="form-contact">
            <div class="col-1">
              <div
                class="input-cont name"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                data-aos-delay="40"
              >
                <label>Nombre:</label>
                <input type="text" v-model="name" name="nombre" />
              </div>
              <div
                class="input-cont inputm"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                data-aos-delay="80"
              >
                <label>Teléfono:</label>
                <input type="text" v-model="phone" name="nombre" />
              </div>
              <div
                class="input-cont inputm"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                data-aos-delay="120"
              >
                <label>Correo:</label>
                <input type="text" v-model="email" name="nombre" />
              </div>
            </div>
            <div class="col-2">
              <div
                class="input-cont"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                data-aos-delay="160"
              >
                <label>Comentarios:</label>
                <textarea type="text" v-model="mensaje" name="nombre" />
              </div>
            </div>
            <div class="col-3"
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-duration="700"
                data-aos-delay="160">
              <p
                class="agriga-descrp"
              >
                Para <b>Agriga de México</b> es muy importante la privacidad y
                seguridad de sus datos personales, le invitamos a leer nuestro

                <a
                  href="/aviso-de-privacidad"
                  target="_blank"
                  rel="noreferrer noopener"
                  >Aviso de Privacidad</a
                >
                antes de enviar el formulario.
              </p>
              <button
                type="submit"
                class="submit-btn"
              >
                <img src="../../assets/avion1.svg" alt="avion" /> ENVIAR
              </button>
            </div>
            <div v-if="this.status == 'success'">
              <p class="cart_msg cart_success">{{ this.msg }}</p>
            </div>
            <div v-if="this.status == 'error'">
              <p class="cart_msg cart_error">{{ this.msg }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="menu-cont">
      <div class="menu-sec">
        <div class="menu-col-1">
          <div class="two-cols">
            <div
              class="menu-col"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="20"
            >
              <p class="menu-titulo">MENÚ</p>
              <a href="/">∙ INICIO</a>
              <a href="/tienda">∙ TIENDA</a>
               <a href="/blog">∙ BLOG</a>
            <!--  <a href="">∙ NOSOTROS</a>
              <a href="">∙ VETERINARIA</a>
                 <a href="">∙ FACTURACIÓN</a>
              -->
             
             
              <a href="#contactanos">∙ CONTACTO</a>
              <div class="sm-cont">
                <a
                  href="https://www.facebook.com/AgrigaMX"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="sm-icon">
                    <img src="../../assets/fb-i-1.svg" alt="" />
                  </div>
                </a>
                <a
                  href="https://www.instagram.com/agrigamx/?hl=es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div class="sm-icon">
                    <img src="../../assets/ins-i-1.svg" alt="" />
                  </div>
                </a>
              </div>
            </div>
            <img
              :src="isWebp(require('../../assets/perrito.webp'), require('../../assets/perrito.png'))" 
              alt="perro"
              class="perrito"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="40"
            />
          </div>
          <div class="suc-col">
            <div
              class="sucursal-cont"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="60"
            >
              <div class="dir-cont">
                <p class="titulo">MATRIZ</p>
                <p class="direccion">
                  Blvd Enrique Mazon 445 B Col. San Luis 83160, Hermosillo, Son.
                </p>
                <p class="telefono">
                  Celular:
                  <a href="tel:+526623380984"> 662 338 0984 </a>
                </p>
              </div>

              <div class="mapa-direction">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1743.1442520783114!2d-110.92998188255613!3d29.097162800000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce85c213a148bf%3A0x3aaf741f657c7183!2sAgriga!5e0!3m2!1ses-419!2smx!4v1652225527793!5m2!1ses-419!2smx"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div
              class="sucursal-cont"
              data-aos="fade-zoom-in"
              data-aos-easing="ease-in-back"
              data-aos-duration="700"
              data-aos-delay="80"
            >
              <div class="dir-cont">
                <p class="titulo">SUCURSAL SUR</p>
                <p class="direccion">
                  Carretera a Sahuaripa #27 Col. Central de Abastos. 83283,
                  Hermosillo, Son.
                </p>
                <p class="telefono">
                  Celular:
                  <a href="tel:+526622783343"> 662 278 3343 </a>
                </p>
              </div>

              <div class="mapa-direction">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1466.7325532146967!2d-110.9400921713712!3d29.031860693755544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ce853a67cde30f%3A0xd6edae7be27a302!2sAgriga%20Parque%20Industrial!5e0!3m2!1ses-419!2smx!4v1652225314451!5m2!1ses-419!2smx"
                  style="border: 0"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div
            class="horario-col"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            <div class="hor-cont">
              <p class="titulo pdlr1">HORARIO DE ATENCIÓN</p>
              <div class="horarios pdlr1">
                <!--<p class="suc-titulo">Matriz</p>-->
                <p class="dia-horas">
                  <span class="lvsd"> Lunes - Viernes: </span>
                  <span class="horasuc"> 8:00 AM - 07:00 PM </span>
                </p>
                <p class="dia-horas">
                  <span class="lvsd"> Sabado: </span>
                  <span class="horasuc"> 8:00 AM - 04:00 PM </span>
                </p>
                <p class="dia-horas">
                  <span class="lvsd"> Domingo (Matriz): </span>
                  <span class="horasuc"> 8:00 AM - 01:00 PM </span>
                </p>
                <!--</div>
              <div class="horarios">
                <p class="suc-titulo">Sucursal Sur</p>
                <p class="dia-horas">
                  <span class="lvsd"> Lunes - Viernes: </span>
                  <span class="horasuc"> 8:00 AM - 07:00 PM </span>
                </p>
                <p class="dia-horas">
                  <span class="lvsd"> Sabado: </span>
                  <span class="horasuc"> 8:00 AM - 04:00 PM </span>
                </p>
              </div>
              <div class="horarios">-->
                <p class="suc-titulo">Correo</p>
                <a href="mailto:ventas@agriga.com.mx" class="correo-agriga"
                  >ventas@agriga.com.mx</a
                >
              </div>
              <div class="card-c pdlr1">
                <p>Métodos de pago aceptados:</p>
                <div class="cards-c">
                  <img src="../../assets/card1.svg" alt="card" />
                  <img src="../../assets/card2.svg" alt="card" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="menu-col-2">
          <p class="copyright">
            Copyright 2022 © Todos los Derechos Reservados.
          </p>
          <a href="/">
            <img src="../../assets/logo-white.svg" alt="logo" class="logo" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      phone: "",
      email: "",
      mensaje: "",
      status: "",
      msg: "",
    };
  },
  methods: {
    delStatus: function () {
      setTimeout(() => this.delMsgs(), 5000);
      /*this.close(), 3000);*/
    },
    delMsgs: function () {
      this.status = "";
      this.msg = "";
    },
    enviarMail: async function (name, phone, email, mensaje) {
      this.msg = "";
      this.status = "";
      if (name == undefined || name == "" || name == null) {
        this.msg = "El campo nombre no es valido.";
        this.status = "error";
      } else if (phone == undefined || phone == "" || phone == null) {
        this.msg = "El campo telefono no es valido.";
        this.status = "error";
      } else if (email == undefined || email == "" || email == null) {
        this.msg = "El campo correo no es valido.";
        this.status = "error";
      } else if (mensaje == undefined || mensaje == "" || mensaje == null) {
        this.msg = "El campo comentarios no es valido.";
        this.status = "error";
      } else {
        let data = {
          name: name,
          phone: phone,
          email: email,
          mensaje: mensaje,
        };
        let response = await this.$store.dispatch(
          "admin/enviarMailContacto",
          data
        );
        if (response.status == "success") {
          this.status = "success";
          this.msg = "Mensaje enviado con exito.";
          this.name = "";
          (this.phone = ""), (this.email = ""), (this.mensaje = "");
          this.delStatus();
        } else {
          this.status = "error";
          this.msg =
            "Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.";
        }
      }
    },
    isWebp(webpImg, pngImg) {
      let ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("chrome") > -1) {
        return webpImg; // Chrome
      } else if (ua.indexOf("firefox") > -1) {
        return webpImg; // Firefox
      } else if (ua.indexOf("opera") > -1) {
        return webpImg; // Opera
      } else if (ua.indexOf("safari") > -1) {
        return pngImg; // Safari
      }
    },
  },
};
</script>
<style scoped>
p {
  font-family: "Helvetica World Regular";
}
#Footer {
  background: linear-gradient(
    180deg,
    #bbe6e6 8.23%,
    #cde8c9 53.05%,
    #ededd3 88.09%
  );
}
.form-contact input[type="text"],
.form-contact textarea {
  padding-left: 0;
  text-indent: 3.9%;
}
.contact-cont {
  /*background-image: url("../../assets/campo1.png");*/
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-y: bottom;
}
.contact-sec {
  padding: 8.802vw 0;
  margin: auto;
  width: 75.75vw;
}
.contact-cont p.titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 2.083vw;
  line-height: 1.963vw;
  color: #fe9128;
  margin: 0;
  padding-bottom: 5.313vw;
}
.input-cont {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.input-cont label {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 0.938vw;
  color: #1c6b21;
  padding-bottom: 1.823vw;
}
.input-cont.name input {
  width: 36.146vw;
  height: 4.167vw;
}
.input-cont.inputm input {
  width: 17.292vw;
  height: 4.167vw;
}
.input-cont input {
  background: transparent;
  border: 1.5px solid #ffffff;
  font-size: 0.781vw;
  line-height: 0.938vw;
  color: #7b7b7b;
  font-family: "Intro Rust";
}
.contact-cont .col-1 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  padding-bottom: 3.073vw;
}
.input-cont textarea {
  width: 74.854vw;
  height: 14.073vw;
  background: transparent;
  border: 1.5px solid #ffffff;
  resize: none;
  font-size: 0.781vw;
  line-height: 0.938vw;
  color: #7b7b7b;
  font-family: "Intro Rust";
  padding-top: 1.5vw;
}
.contact-cont .col-2 {
  padding-bottom: 3.854vw;
}
.contact-cont .col-3 {
  display: flex;
  justify-content: space-between;
}
p.agriga-descrp {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 174.19%;
  color: #ffffff;
  width: 35.094vw;
}
p.agriga-descrp a {
  text-decoration-color: transparent;
  color: #fe9128;
}
.submit-btn {
  width: 15.573vw;
  height: 3.906vw;
  background: #fe9128;
  border: none;
  color: white;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
  line-height: 2.031vw;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  cursor: pointer;
}
.submit-btn:hover {
  background: #db791e;
}
button.submit-btn img {
  width: 1.198vw;
  height: auto;
  padding-right: 0.833vw;
}

.menu-cont {
  background-color: #0a2b12;
}
.menu-col-1 {
  padding: 4.219vw 0 0;
  margin: 0 4.479vw 0;
  display: flex;
  border-bottom: 1px solid white;
}
.menu-col-1 .menu-col {
  display: flex;
  flex-direction: column;
  padding-top: 4.063vw;
  padding-left: 4.583vw;
}
.menu-col-1 .menu-col a {
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 280.57%;
  color: #cde8c9;
  text-decoration-color: transparent;
  transition: 0.5s;
  font-family: "Helvetica World Regular";
}
.menu-col-1 .menu-col a:hover {
  text-decoration-color: #cde8c9;
}
.menu-col-1 .sm-icon img {
  width: auto;
  height: 0.691vw;
}
.menu-col-1 .sm-cont {
  display: flex;
  width: 5.248vw;
  padding-top: 2.083vw;
  justify-content: space-between;
}
p.menu-titulo {
  margin: 0;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.938vw;
  line-height: 1.563vw;
  color: #ffffff;
  padding-bottom: 2.083vw;
}
.menu-col-1 .sm-icon {
  width: 2.217vw;
  height: 2.161vw;
  border: 0.104vw solid #47604d;
  border-radius: 2.865vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu-col-1 img.perrito {
  width: 23.385vw;
  height: auto;
}
.menu-col-1 .suc-col {
  display: flex;
  width: 29.115vw;
  justify-content: space-between;
}
.menu-col-1 .horario-col {
  background-image: url("../../assets/line-1.svg");
  background-size: 0.062vw;
  background-repeat: no-repeat;
  background-position-y: center;
  margin-left: 3.646vw;
  padding-left: 3.646vw;
}
.menu-col-1 .hor-cont {
  padding-top: 4.115vw;
}
.menu-col-1 .sucursal-cont {
  width: 13.385vw;
  height: 21.771vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 4.115vw;
}
.menu-col-1 p.titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 0.781vw;
  line-height: 1.563vw;
  color: #ffffff;
  margin: 0 0 1.615vw;
}
.menu-col-1 .direccion,
.menu-col-1 .telefono,
.menu-col-1 .telefono a {
  font-style: normal;
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 182%;
  color: #e3f1e5;
  text-decoration-color: transparent;
  transition: 0.5s;
}
.menu-col-1 .telefono a:hover {
  text-decoration-color: #e3f1e5;
}
.menu-col-1 p.direccion {
  margin: 0 0 1.875vw;
}
.menu-col-1 .telefono {
  margin: 0;
}
.menu-col-1 .mapa-direction {
  width: 13.385vw;
  height: auto;
}
.menu-col-1 .mapa-direction iframe {
  width: 100%;
  height: 100%;
}
.menu-col-1 p.suc-titulo {
  margin: 0;
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 1.563vw;
  color: #fe9128;
}
.menu-col-1 p.dia-horas {
  margin: 0;
  font-size: 0.729vw;
  line-height: 193.5%;
  color: white;
  display: flex;
  flex-direction: column;
}
.menu-col-1 p.dia-horas .lvsd {
  font-weight: 700;
  color: #7dd1a3;
}
.menu-col-1 .horarios {
  padding-bottom: 1.865vw;
  border-bottom: 0.052vw solid #36593e;
}
.menu-col-1 a.correo-agriga {
  font-size: 0.729vw;
  line-height: 193.5%;
  color: white;
  transition: 0.5s;
  text-decoration-color: transparent;
}
.menu-col-1 a.correo-agriga:hover {
  text-decoration-color: white;
}
.menu-col-2 {
  margin: 0 4.479vw 0;
  padding: 0.885vw 0 1.51vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.menu-col-2 p.copyright {
  font-weight: 400;
  font-size: 0.677vw;
  line-height: 374.07%;
  color: #ffffff;
  margin: 0;
}
.menu-col-2 img.logo {
  width: 6.719vw;
  height: auto;
}
.two-cols {
  display: flex;
}
.pdlr1 {
  padding: 0 2.188vw;
}
.card-c {
  margin-top: 1.589vw;
}

.card-c p {
  font-size: 0.573vw;
  line-height: 257%;
  color: #7dd1a3;
  margin: 0;
}
.cards-c img {
  width: 1.719vw;
}
.cards-c {
  width: 3.802vw;
  display: flex;
  justify-content: space-between;
  margin-top: 0.156vw;
}
@media (max-width: 768px) {
  .contact-cont p.titulo {
    font-size: 5.314vw;
    line-height: 6.314vw;
    padding-bottom: 15.942vw;
  }
  .contact-sec {
    padding: 19.807vw 0 100vw;
    width: 75.75vw;
  }
  .contact-cont .col-1 {
    flex-direction: column;
    padding-bottom: 0;
  }
  .input-cont {
    width: 100%;
    padding-bottom: 7.246vw;
  }
  .input-cont.name input,
  .input-cont.inputm input {
    width: 100%;
    height: 13.043vw;
  }
  .input-cont label {
    font-size: 3.623vw;
    line-height: 4.348vw;
    padding-bottom: 7.246vw;
  }
  .input-cont input {
    font-size: 3.623vw;
    line-height: 4.348vw;
  }
  .input-cont textarea {
    width: 100%;
    height: 35.266vw;
    font-size: 3.623vw;
    line-height: 4.348vw;
  }
  p.agriga-descrp {
    font-size: 2.657vw;
    width: 100%;
    color: #929292;
    padding-bottom: 16.908vw;
  }
  .contact-cont .col-3 {
    flex-direction: column;
  }
  .submit-btn {
    width: 74.879vw;
    height: 18.116vw;
    font-size: 3.865vw;
    line-height: 9.42vw;
  }
  button.submit-btn img {
    width: 4.198vw;
    padding-right: 2.833vw;
  }
  .contact-cont {
    background-size: 267.15vw;
    background-position-x: -37vw;
  }
  .two-cols {
    display: flex;
    justify-content: space-between;
    padding-bottom: 7.971vw;
    border-bottom: 0.052vw solid #36593e;
  }
  .menu-col-1 {
    padding: 13.768vw 0 0;
    margin: 0 8.527vw 0;
    display: flex;
    border-bottom: 0.052vw solid #36593e;
    flex-direction: column;
    overflow: hidden;
  }
  .menu-col-1 .menu-col {
    flex-direction: column;
    padding-top: 0;
    padding-left: 0;
  }
  p.menu-titulo {
    font-size: 4.348vw;
    line-height: 7.246vw;
    padding-bottom: 10.145vw;
  }
  .menu-col-1 p.titulo {
    font-size: 4.348vw;
    line-height: 7.246vw;
    padding-bottom: 10.145vw;
  }
  .menu-col-1 .suc-col {
    width: 100%;
    flex-direction: column;
    padding-bottom: 12.319vw;
    border-bottom: 0.052vw solid #36593e;
  }
  .menu-col-1 .sucursal-cont {
    width: 100%;
    height: fit-content;
    padding-top: 8.696vw;
  }
  .menu-col-1 .menu-col a {
    font-size: 2.415vw;
    line-height: 280.57%;
  }
  .menu-col-1 img.perrito {
    width: 59.42vw;
    height: auto;
    position: relative;
    left: 12vw;
  }
  .menu-col-1 .sm-cont {
    display: none;
  }
  .menu-col-1 .direccion,
  .menu-col-1 .telefono,
  .menu-col-1 .telefono a {
    font-style: normal;
    font-weight: 400;
    font-size: 3.382vw;
    line-height: 182%;
  }
  .menu-col-1 .mapa-direction {
    width: 100%;
    height: 32.85vw;
    padding-top: 8.454vw;
  }
  .menu-col-1 .horario-col {
    margin-left: 0;
    padding-left: 0;
    padding-top: 10.145vw;
  }
  .menu-col-1 p.suc-titulo {
    font-size: 3.382vw;
    line-height: 7.246vw;
  }
  .menu-col-1 p.dia-horas {
    font-size: 3.382vw;
    line-height: 193.5%;
  }
  .menu-col-1 a.correo-agriga {
    font-size: 3.382vw;
    line-height: 193.5%;
  }
  .menu-col-1 .horarios {
    padding-bottom: 9.662vw;
  }
  .menu-col-1 p.direccion {
    margin: 0 0 1.875vw;
    width: 61vw;
  }
  .menu-col-2 p.copyright {
    font-size: 2.415vw;
    line-height: 164.07%;
    width: 37vw;
  }
  .menu-col-2 img.logo {
    width: 19.324vw;
    height: auto;
  }
  .menu-col-2 {
    margin: 0 27.479vw 0 8.479vw;
    padding: 9.42vw 0 9.42vw;
    justify-content: space-between;
  }
  .pdlr1 {
    padding: 0;
  }
  .card-c {
    margin-top: 7.009vw;
  }
  .card-c p {
    font-size: 3.271vw;
    line-height: 257%;
  }
  .cards-c {
    width: 28.972vw;
    margin-top: 1.636vw;
    margin-bottom: 8.879vw;
  }
  .cards-c img {
    width: 13.084vw;
}
}
</style>
