<template>
  <div id="Menu">
    <div class="menu-cont">
      <a href="/">
        <img
          src="../../assets/logo-agriga.png"
          alt="logo"
          class="logo-agriga"
        />
      </a>
      <input type="checkbox" name="check" id="checkmenu" />
      <label for="checkmenu">
        <div class="btn-menu">
          <img src="../../assets/menu-bars.svg" alt="menu" />
        </div>
      </label>

      <div class="search-nav-cont">
        <div class="col-cont uno">
          <div class="search-cont">
            <a class="lupa-btn" :href="'/tienda/search/'+searchPro">
              <img src="../../assets/lupa-i.svg" alt="btn" class="search-btn" />
            </a>
            <input type="search" id="site-search" name="q" placeholder="Buscar productos..." v-model="searchPro"
            @keyup.enter="submit(searchPro)"/>
          </div>
          <a
            href="/mi-cuenta/escritorio"
            v-if="identity.role == 'cliente'"
            class="mlcent menu-t"
          >
            HOLA, <span>{{ identity.name }}</span>
            <img
              src="../../assets/user-i-logged.svg"
              alt="btn"
              class="icon-btn user-i"
            />
          </a>
          <a
            href="/administrador"
            v-else-if="identity.role == 'admin'"
            class="mlcent menu-t"
          >
            HOLA, <span>{{ identity.name }}</span>
            <img
              src="../../assets/user-i-logged.svg"
              alt="btn"
              class="icon-btn user-i"
            />
          </a>
          <a href="/login" class="mcentered" v-else>
            <img
              src="../../assets/user-i.svg"
              alt="btn"
              class="icon-btn user-i"
            />
          </a>
          <a href="/carrito" class="dflx">
            <img src="../../assets/carrito-i.svg" alt="btn" class="icon-btn" />
            <div class="cart-num">{{ numItems }}</div>
          </a>
        </div>
        <div class="col-cont dos">
         
          <a href="/" class="menu-t"> INICIO </a>
         
          <a href="/tienda" class="menu-t"> TIENDA </a>
       
          <a href="/blog" class="menu-t"> BLOG </a>

          
        <!--  <a href="" class="menu-t"> NOSOTROS </a>
          
          <a href="" class="menu-t"> VETERINARIA </a>
         
         
          <a href="" class="menu-t"> FACTURACIÓN </a> -->
         
          <a href="#contactanos" class="menu-t"> CONTACTO </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  async created() {
    await this.getIdentity();
  },
  data() {
    return {
      carNum: localStorage.getItem("cart"),
      searchPro: '',
    };
  },
  async created() {
    await this.getIdentity();
    await this.obtenerCarritos();
  },
  computed: {
    identity() {
      return this.$store.getters["admin/getIdentity"];
    },
    numItems() {
      let arr = this.$store.getters["carrito/getcar"];
      if(arr.items){
        return arr.items.length;
      }else{
        return 0;
      }
    },
  },
  methods: {
    ...mapActions("carrito", ["getCart"]),
    async obtenerCarritos() {
      await this.getCart();
    },
    getIdentity: async function () {
      let response = await this.$store.dispatch("admin/getData");
      return response;
    },
    submit(value) {
      return window.location.replace("/tienda/search/" + value);
    },
  },
};
</script>

<style scoped>
a,
p {
  font-family: "Helvetica World Regular";
  text-decoration-color: transparent;
}
.menu-cont {
  width: 88.542vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#Menu {
  padding: 1.823vw 0;
  display: flex;
  justify-content: center;
}
.logo-agriga {
  width: 10.104vw;
  height: auto;
}
.search-nav-cont {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.col-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.col-cont.uno {
  /*width: 22.188vw;*/
  width: auto;
  margin-bottom: 2.083vw;
}
.col-cont.dos {
  width:23.75vw;
}
.lupa-btn {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}
.search-btn {
  width: 0.677vw;
  height: auto;
}
.icon-btn {
  width: auto;
  height: 0.833vw;
    position: relative;
}
.icon-btn.user-i {
  width: auto;
  height: 0.781vw !important;
}
.cart-num {
  width: 0.938vw;
  height: 0.938vw;
  background: #fe9128;
  position: absolute;
  font-family: var(--Poppins);
  font-style: normal;
  font-weight: 700;
  font-size: 0.521vw;
  line-height: 0.625vw;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
    border-radius: 14vw;
    margin-left: 0.573vw;
}

#site-search {
  width: 13.573vw;
  border: none;
  padding: 0;
  font-family: var(--Poppins);
  font-size: 0.729vw;
  line-height: 0.833vw;
  text-indent: 4%;
}
.search-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17.344vw;
  height: 2.083vw;
  border: 0.5px solid #929292;
  box-sizing: border-box;
  border-radius: 1.563vw;
}
.menu-t {
  font-weight: 900;
  font-size: 0.729vw;
  line-height: 0.833vw;
  color: #929292;
  text-decoration-color: transparent;
  transition: 0.5s;
}
.menu-t span {
  text-transform: uppercase;
  color: #fe9128;
  margin-right: 0.313vw;
}
.menu-t:hover {
  color: #3ebdb1;
}
.btn-menu {
  display: none;
}
input#checkmenu {
  display: none;
}
@media (min-width: 768px) {
  a.mcentered {
    margin: 0 1.458vw;
  }
  .mlcent {
    margin: 0 1.458vw 0 4.458vw !important;
  }
}

@media (max-width: 768px) {
  .btn-menu {
    display: block;
    cursor: pointer;
  }
  .btn-menu img {
    width: 9.42vw;
    height: auto;
  }

  #checkmenu:checked ~ .search-nav-cont {
    height: 129vw;
    pointer-events: all;
    opacity: 1;
  }
  .search-nav-cont {
    opacity: 0;
    transition: 0.5s;
    pointer-events: none;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 0;
    top: 22vw;
    left: 0;
    z-index: 99;
    background-color: white;
  }
  .logo-agriga {
    width: 29.71vw;
  }
  .menu-cont {
    width: 83.092vw;
  }
  .col-cont {
    justify-content: space-evenly;
  }
  .search-btn {
    width: 3.677vw;
    height: auto;
  }
  .col-cont.dos {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  #site-search {
    width: 53.573vw;
    font-size: 2.729vw;
  }
  .menu-t {
    font-size: 5.729vw;
    line-height: 11.833vw;
    font-weight: 400;
  }
  .col-cont.uno {
    width: 70%;
    flex-wrap: wrap;
    margin-bottom: 14.083vw;
  }
  .search-cont {
    width: 67.344vw;
    height: 10.083vw;
    border-radius: 15.563vw;
    margin: 7vw 0;
  }
  .icon-btn.user-i {
    width: auto;
    height: 6.781vw !important;
  }
  .icon-btn {
    width: auto;
    height: 6.833vw;
  }
  .cart-num {
    width: 6.938vw;
    height: 6.938vw; 
    font-size: 3.938vw;
    line-height: 3.938vw; 
    border-radius: 14vw;
    margin-left: 4.573vw;
  }
}
</style>